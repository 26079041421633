html,
body,
#root {
  height: 100%
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



#root>main {
  /* height of fixed-top header */
  padding-top: 172px;
}

#root>header {
  z-index: 1000;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 20px 0;
}

/* For the landing page */
.spaced-section {
  padding: 180px 0;
}

.section-bg {
  background-color: #f8fbfe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #124265;
}

.section-title p {
  margin-bottom: 0;
  font-size: 14px;
  color: #919191;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.main-c {
  height: 100vh;
  position: relative;
}

.fill {
  min-height: 100%;
  height: 100%;
}

.slim-jumbo {
  padding: 2rem 2rem;
  margin-bottom: 0.5rem;
}

.mint-card-deck {
  flex-wrap: wrap;
  justify-content: center;
}

.mint-card-deck-col {
  order: 0;
}

@media only screen and (max-width: 768px) {
  .mint-card-deck-col {
    order: 1;
  }
}

.wallet-card-deck {
  flex-wrap: wrap;
  justify-content: space-between;
}

.stamp-card-wallet:hover {
  transform: scale(1.1);

}

.stamp-card-wallet {
  transition: all .2s ease-in-out;
  flex: none !important;
  flex-basis: 240px !important;
  margin-top: 12px !important;
  margin-right: 12px !important;
  margin-left: 12px !important;
  margin-bottom: 12px !important;
  min-width: 15rem;
}

.stamp-card-image {
  width: 238;
  height: 170;
}

.stamp-card-mint {
  flex: none !important;
  flex-basis: 240px !important;
  margin-top: 12px !important;
  margin-right: 12px !important;
  margin-left: 12px !important;
  margin-bottom: 12px !important;
}

@keyframes anim-glow-frame {
  0% {
    box-shadow: 0px 0px 20px 10px #61EF61;
  }

  100% {
    box-shadow: 0 0 20px 10px transparent;
  }
}

.anim-glow {
  animation: anim-glow-frame 8s ease-out infinite;
}


.nav-lang-header-text {
  font-family: Roboto, helvetica, sans-serif;
  font-size: 13px;
}

.navbar-toggler-icon {
  height: 0.6em;
  width: 0.6em;
}

.navbar-collapse.collapse.show {
  background-color: white;
  border-color: rgba(0, 0, 0, .1);
  border-radius: 5px;
  border-style: solid;
  border-width: 1x !important;
}

.un-lang-navbar {
  box-sizing: border-box;
  background-color: #f2f2f2;
  margin: 0px;
  padding: 0px;
  padding-top: 4px;
  padding-bottom: 3px;
  border: 0px;
  padding-left: 60px;
  padding-right: 60px;
  height: 41px;
}

.un-blue-border-bottom {
  border-bottom-color: #009edb;
  border-bottom-style: solid;
  border-bottom-width: 5px;
}

.nav-un-crypto-brand {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 40px;
}

@media only screen and (max-width: 650px) {
  .nav-un-crypto-brand-img {
    display: none !important;
  }
}

.nav-stamp-search-input.form-control.is-invalid {
  padding-right: inherit;
}

@media only screen and (max-width: 540px) {
  .nav-stamp-search-input {
    width: 180px;
  }
}

@media only screen and (max-width: 450px) {
  .nav-lang-header-text {
    font-size: 11px
  }

  .un-lang-navbar {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-stamp-search-input {
    width: 140px;
  }

  .un-crypto-brand {
    font-size: 32px;
  }
}

@media only screen and (max-width: 400px) {
  .un-crypto-brand {
    font-size: 23px;
  }

  .nav-stamp-search-input {
    width: 130px;
  }
}

@media only screen and (max-width: 370px) {
  .nav-lang-header-text {
    font-size: 9px
  }

  .nav-stamp-search-input {
    width: 110px;
  }
}

@media only screen and (max-width: 330px) {
  .nav-stamp-search-input {
    width: 70px;
  }

  .un-crypto-brand {
    font-size: 20px;
  }
}

@media only screen and (max-width: 281px) {
  .un-crypto-brand {
    font-size: 12px;
  }
}

.stamp {
  width: 300px;
  height: 200px;
  display: inline-block;
  padding: 10px;
  background: white;
  position: relative;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  /*The stamp cutout will be created using crisp radial gradients*/
  background: radial-gradient(transparent 0px,
      transparent 4px,
      white 4px,
      white);
  /*reducing the gradient size*/
  background-size: 20px 20px;
  /*Offset to move the holes to the edge*/
  background-position: -10px -10px;
}

.stamp:after {
  content: '';
  position: absolute;
  /*We can shrink the pseudo element here to hide the shadow edges*/
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  /*Shadow - doesn't look good because of the stamp cutout. We can still move this into another pseudo element behind the .stamp main element*/
  /*box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.5);*/
  /*pushing it back*/
  z-index: -1;
}

.stamp-properties {
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
}

.stamp-properties .sdg-wheel {
  height: 30px;
  width: auto;
}

@media only screen and (max-width: 550px) {
  .stamp-properties {
    font-size: 1.1rem;
  }

  .stamp-properties .sdg-wheel {
    height: 24px;
  }
}

@media only screen and (max-width: 450px) {
  .stamp-properties {
    font-size: 0.9rem;
  }

  .stamp-properties .sdg-wheel {
    height: 18px;
  }
}

.stamp-properties>div.row>div {
  height: 45px;
  vertical-align: middle;
  background-color: lightgray;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid white;
}

.prop-col-2>div.row>div {
  vertical-align: middle;
}

.footer-un2 {
  border-top-color: #009edb;
  border-top-style: solid;
  border-top-width: 5px;
}

.footer-un2 .footer-un-brand>a>img {
  width: 11rem;
  height: auto;
}

.footer-un2 .social-media {
  margin-top: 1.2rem;
}

.footer-un2 .social-media .nav.list-inline {
  float: right;
}

.footer-un2 .social-media li a {
  padding: 0;
  margin-right: 1.8rem;
  background-color: transparent;
  display: inline-block;
}

.footer-un2 .footer-links .nav li>a {
  font-weight: 500;
  font-size: 12px;
  font-size: 0.75rem;
  text-decoration: none;
  color: white;
  letter-spacing: 0.77px;
  line-height: 1.1667;
  padding: 0;
  text-transform: uppercase;
}

.footer-un2 .footer-links .nav li {
  padding: 0 1.3rem 0 1rem;
  border-right: gray solid 0.3rem;
}

.footer-un2 .footer-links .nav li.last {
  padding-right: 0;
  border-right: none;
}

.footer-un2 .footer-links .nav li>a:hover,
.footer-un2 .footer-links .nav li>a:focus {
  background-color: transparent;
  outline: none;
}

.footer-un2 .footer-links .divider {
  border-top: #5b5b5b solid 1px;
  margin: 1.6rem 0 1.9rem 0;
  padding: 0 1rem;
  width: 100%;
}

.footer-un2 .list-inline>li {
  display: inline-block;
}

.footer-un2 .social-media li a span.icon-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.footer-un2 .social-media li a::before {
  font-size: 24px;
  font-size: 2.4rem;
  color: #c4c4c4;
  display: inline-block;
}

.footer-un2 .social-media li a:hover,
.social-media li a:focus {
  background-color: transparent;
  outline: none;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}

.greyed-out>img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.greyed-out>img:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

@media only screen and (max-width: 575px) {
  .wallet-btn-collection {
    cursor: not-allowed;
    pointer-events: none;
    -webkit-filter: grayscale(0%);
    filter: grayscale(100%);
  }
}

@media only screen and (max-width: 575px) {
  .wallet-btn-ctrl {
    display: none;
  }
}