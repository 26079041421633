
.stamp-card {
    width: 95%;
    position: relative;
    max-width: 800px;
    margin: auto;
    background: #fff;
    box-shadow: 0 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 25px;
    height: 400px;
    transition: all 0.3s;
}
@media screen and (max-width: 992px) {
    .stamp-card {
        max-width: 680px;
        height: 400px;
    }
}
@media screen and (max-width: 768px) {
    .stamp-card {
        min-height: 500px;
        height: auto;
        margin: 180px auto;
    }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
    .stamp-card {
        height: 350px;
    }
}
.stamp-card__item {
    display: flex;
    color: #4e4a67;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .stamp-card__item {
        flex-direction: column;
    }
}
.stamp-card__item.swiper-slide-active .stamp-card__img img {
    opacity: 1;
    transition-delay: 0.3s;
}
.stamp-card__item.swiper-slide-active .stamp-card__content > * {
    opacity: 1;
    transform: none;
}
.stamp-card__img {
    width: fit-content;
    flex-shrink: 0;
    height: 250px;
    box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
    border-radius: 20px;
    transform: translateX(-80px);
    overflow: hidden;
}
.stamp-card__img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: 0.8;
}
.stamp-card__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    opacity: 0;
    border-radius: 20px;
    transition: all 0.3s;
}
@media screen and (max-width: 768px) {
    .stamp-card__img {
        transform: translateY(-50%);
        width: 90%;
    }

    .stamp-card__img img{
        object-fit: unset;
    }
}
@media screen and (max-width: 576px) {
    .stamp-card__img {
        width: 95%;
    }

    .stamp-card__img img{
        object-fit: unset;
    }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
    .stamp-card__img {
        height: 270px;
    }
}
.stamp-card__content {
    padding-right: 25px;
}
@media screen and (max-width: 768px) {
    .stamp-card__content {
        margin-top: -80px;
        text-align: center;
        padding: 0 30px;
    }
}
@media screen and (max-width: 576px) {
    .stamp-card__content {
        padding: 0;
    }
}
.stamp-card__content > * {
    opacity: 0;
    transform: translateY(25px);
    transition: all 0.4s;
}
.stamp-card__code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500;
}
.stamp-card__title {
    font-size: 24px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 20px;
}
.stamp-card__text {
    color: #4e4a67;
    font-weight: 500;
    word-break: break-word;
    margin-bottom: 20px;
    line-height: 1.5em;
}
.stamp-card__text img{
    vertical-align: unset;
}

@media screen and (max-width: 576px) {
    .stamp-card__button {
        width: 100%;
    }
}
