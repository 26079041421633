/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;    
    position: relative;
    background: url("../../images/hero-bg.jpg") top center;
    background-size: cover;
  }

#hero .welcome-logo {  
    margin-left: auto!important;
    margin-right: auto!important;
    width: 90px;
    height: 69px;
    background-size: 90px;
    background-image: url("../../images/UNPA-pigeon-UN-emblem.png");
    background-repeat: no-repeat;
    background-position: top center;
}

#hero .container {
  padding-top: 40px;
  padding-bottom: 0px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
  #hero .welcome-logo {
    border: 0px solid pink;
    position: absolute;
    top: -30px;
    left: 50px;
    width: 90px;
    height: 69px;
    background-size: 90px;
  }
  #hero .container {
    padding-top: 60px;
    padding-bottom: 0px;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  #hero .welcome-logo {
    border: 0px solid pink;
    position: absolute;
    top: -30px;
    left: 136px;
    width: 90px;
    height: 69px;
    background-size: 90px;
  }
 
}
 
/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #hero .welcome-logo {
    border: 0px solid pink;
    position: absolute;
    top: -50px;
    left: -50px;
    width: 160px;
    height: 125px;
    background-size: 160px;
  }
  #hero .container {
    padding-top: 80px;
    padding-bottom: 180px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  #hero .welcome-logo {
    border: 0px solid pink;
    position: absolute;
    top: -50px;
    left: -107px;
    width: 160px;
    height: 125px;
    background-size: 160px;
    z-index: -1;
  }    
}

#hero .mainc {
    z-index: 50
}

div.event-toasts {
    position: absolute;
    min-height: 50%;
    max-height: 100%;
    min-width: 350px;
    float: right;
    margin-right: 15px;
    right: 0;
    margin-top: 15px;
    overflow: visible;
    border: 0px solid grey;
    z-index: 1;
    opacity: 0.09;
}

div.event-toasts:hover {
    z-index: 100;
    opacity: 1;
    cursor: pointer;
}

div.event-toast {
    min-width: 250px;    
}

#hero .icon {
  width:100%;
  display:flex!important;
  justify-content:center;
  align-items:center;
}

#hero .bg-icon {
    border: 0px solid grey;
    display: inline-block;
    width: 160px;
    height: 160px;
    background-repeat: no-repeat!important;
    background-position: center center!important;
}

#hero .icon-boxes {
  margin-top: 60px;
}

#hero .icon-box {
  padding: 10px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

#hero .what-icon {
    background: url("../../images/UNPA-crypto-what.png");
    background-size: auto 160px;
}

#hero .trade-icon {
    background: url("../../images/UNPA-crypto-trade.png");
    background-size: auto 156px;
}

#hero .activate-icon {
    background: url("../../images/UNPA-crypto-activate.png");
    background-size: auto 160px;
}

#hero .shop-icon {
    background: url("../../images/UNPA-crypto-shop.png");
    background-size: auto 160px;
}


  #hero:before {
    content: "";
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 72px;
    color: #124265;
    font-family: "Poppins", sans-serif;
  }
  
  #hero h2 {
    color: #5e5e5e;
    margin: 10px 0 0 0;
    font-size: 22px;
  }
  
  #hero .btn-get-started {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 14px 50px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 30px;
    color: #fff;
    background: #2487ce;
  }
  
  #hero .btn-get-started:hover {
    background: #3194db;
  }
  
  #hero .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 22px;
    text-align: center;
  }
  
  #hero .icon-box .title a {
    color: #124265;
    transition: 0.3s;
  }
  
  #hero .icon-box .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  #hero .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 36px;
    line-height: 1;
    color: #2487ce;
  }
  
  #hero .icon-box:hover {
    transform: scale(1.08);
  }
  
  #hero .icon-box:hover .title a {
    color: #2487ce;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 992px), (max-height: 500) {
    #hero {
      height: auto;
    }
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .box-shadow-1 {
    -webkit-box-shadow: 7px 7px 10px -2px rgba(0,0,0,0.6);
    -moz-box-shadow: 7px 7px 10px -2px rgba(0,0,0,0.6);
    box-shadow: 7px 7px 10px -2px rgba(0,0,0,0.6);
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .content {
    padding-top: 0px;
    padding-bottom: 10px;
  }

  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li + li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #2487ce;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #2487ce;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #2487ce;
  }
  
  .about .content .btn-learn-more:hover {
    background: #2487ce;
    color: #fff;
    text-decoration: none;
  }
